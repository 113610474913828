import React, { useEffect } from 'react'
import ReactGooglePlacesAutocomplete ,{ geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { apiKey } from './asset';
import { styled, Box } from '@material-ui/core';

interface LocationField {
    latitide : number,
    longitude: number,
    location : string
}
interface Props {
    locationFields: (locationData : LocationField) => void
}

const GoogleAutoComplete : React.FC<Props>= ({ locationFields}) => {

    const loadScript = (url: string) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      };

    useEffect(() => {
        const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        loadScript(googleMapsScriptUrl);
      }, []);
    return (
        <Wrapper>
            <ReactGooglePlacesAutocomplete
                apiKey={apiKey}
                debounce={100}
                selectProps={{
                    isClearable: false,
                    classNamePrefix: "locationField",
                    placeholder: "Enter address of need",
                    noOptionsMessage: () => null,
                    styles: {
                        input: (provided: any) => ({
                          ...provided,
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#667085",
                            fontFamily: 'Plus Jakarta Sans',
                        }),
                        control: (provided: any) => ({
                          ...provided,
                            background: "white",
                            borderRadius: "12px",
                            margin: "5px 0"
                        }),
                        menu: (provided: any) => ({
                          ...provided,
                          backgroundColor: '#fff',
                          border: '1px solid #CBD5E1',
                        }),
                        option: (provided: any, state: { isFocused: boolean }) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#ddd' : '#fff',
                            color: '#23395D',
                            padding: '10px',
                            fontSize: "18px",
                            fontWeight: "500",
                            fontFamily: 'Plus Jakarta Sans',
                        }),
                      },
                    onChange: async (event: any) => {
                        const location = event?.value?.description ?? "";
                        geocodeByAddress(location)
                            .then(results => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                                const locationData = {
                                    latitide: lat,
                                    longitude: lng,
                                    location: location,
                                }
                                locationFields(locationData)
                            });
                    },
                }}
            />
        </Wrapper>
    )
}

const Wrapper = styled(Box)({
    "& .locationField" : {
        background: "white",
        height: "45px",
        borderRadius: "12px",
        margin: "5px 0"
      }
})

export default GoogleAutoComplete