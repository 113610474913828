// Customizable Area Start
import React from "react";

import {
  Tabs,
  Tab,
  Box,
  styled,
  Grid,
  Typography,
  Button,
  IconButton, 
  OutlinedInput,
  InputAdornment,
  Card,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core"
import { bookingIcon, upcomingIcons, settingIcon, helpcareIcon,
   hostBtnLogo, list1,list2,list3,list4, listCarIcon,listHomeIcon, reload, download,emojiIcon, replyIcon,
    greenTick, lightTick, arrowRight, carPic, homePic, msgIcon } from "./assets";
import {  searchIcon } from "../../../blocks/reservations/src/assets"; 
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import SimpleImageSlider from "react-simple-image-slider";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HostDashboardController, {
    Props,DataItem,
    configJSON, ReviewData
  } from "./HostDashboardController";
import EmojiPicker from "emoji-picker-react";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web"
import CircularProgressWithLabel, {CircularProgressReview, TabPanel} from "../../../components/src/CustomCircularBox";
import Rating from "@material-ui/lab/Rating";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts"
import Schedule from "../../../components/src/HostSchedule.web";
import CustomStepper from "../../../components/src/CustomStepper.web";

class HostDashboard extends HostDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  handleTabView = (value : number) => {
    switch(value) {
        case 0:
            return this.OverViewWrapper();
        case 1:
            return this.loadData();
        case 2:
            return this.EarningsWrapper();
        case 3:
            return this.ReviewsWrapper();
    }
  }

  tmpData = [
    {label : "Upcoming Bookings", icon: bookingIcon, navigate : ""},
    {label : "Booking History", icon: upcomingIcons, navigate : "BookingHistory"},
    {label : "Host Care & Support", icon: helpcareIcon, navigate : ""},
    {label : "Host Settings", icon: settingIcon, navigate : "HostSettings"},
  ]
  OverViewWrapper = () => {
    return (
        <OverViewTabWrapper>
              <p className="scheduleText">Schedule</p>

          {this.state.loaderOverView ? <Loader loading /> : <Box className="scheduleBox">
            <Schedule scheduleData={this.state.hostScheduleData} />
          </Box>}

              <Box style={{ width: "100%", maxWidth: "1220px"}}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                {this.tmpData.map((itemVal:any) => 
                   <Grid item key={itemVal} sm={3}>
                      <Box className="gridBoxes">
                      <Box style={{ textAlign: "center" }}>
                        <img src={itemVal.icon} alt="" />
                        <Typography className="titleText"> {itemVal.label}</Typography>
                        <Button className="viewBtn" data-test-id="viewBtn" onClick={() => this.navigateToGivenPage(itemVal.navigate)} variant="outlined" color="primary">View</Button>
                        </Box>
                      </Box>
                   </Grid>
                )}
                </Grid>
                 
              </Box>
        </OverViewTabWrapper>
    )
  }

  renderNoDataTextSection = () => {
    return (
        <Typography className="scheduleText">No Data Available</Typography>
    )
  }

  renderPayoutTabSection = () => {
    return (
      <Box>
        {this.state.hostPayoutData.length > 0 ? this.state.hostPayoutData.map((data, index) => {
          return (
            <Box className="payoutBox" key={index} onClick={() => this.handlePayoutModal(data)} data-test-id="payoutModalOpen">
              <div className="details">
                <img className="payoutImg" src={this.handlePayouTCondition(data.title) ? download : reload} />
                <div className="payoutPrice">
                  <div className="revenue">
                    $ {data.amount}
                  </div>
                  <div className="estimated">
                    <Typography className="estimatedText">
                      Estimated on {data.created_at}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="arrowAndTick">
                <img src={this.handlePayouTCondition(data.title) ? greenTick : lightTick} className="tickIcon" />
                <img src={arrowRight} className="arrowIcon" />
              </div>
            </Box>
          )
        }) : this.renderNoDataTextSection()}
      </Box>
    )
  } 

  renderTransactionTabSection = () => {
    return (
      <Box>
        {this.state.hostTransactionData.length > 0 ? this.state.hostTransactionData.map((arrVal, index) => {
          return (
            <Box className="payoutBox" key={index} onClick={() => this.handleTransactionModal(arrVal)} data-test-id="transactionModalOpen">
              <div className="details">
                <img className="payoutImg" src={arrVal.bookSlotType ? homePic : carPic} />
                <div className="payoutPrice">
                  <div className="revenue">
                    $ {arrVal.amount}
                  </div>
                  <div className="estimated">
                    <Typography className="estimatedText">
                      {arrVal.booked_slots[0].catalogueName} ({arrVal.booked_slots[0].startDate} - {arrVal.booked_slots[0].endDate})
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="arrowAndTick">
                <img src={arrVal.transactionStatus ? greenTick : lightTick} className="tickIcon" />
                <img src={arrowRight} className="arrowIcon" />
              </div>
            </Box>
          )
        }) : this.renderNoDataTextSection()}
      </Box>
    )
  }

  loadData = () => {
    return (
      this.state.loader ? <Loader loading /> : this.ListingWrapper()
    )
  }

  ListingWrapper = () => {
    return (
        <ListingTabWrapper>
          <Box style={{ display: 'flex', justifyContent: "space-between"}} className="searchMainListingBox">
              <OutlinedInput
              placeholder="Search here"
              startAdornment={<img src={searchIcon} />}
              className="searchBox"
              disabled={this.state.listData.length === 0}
              />
             <Button className="addBtn" data-test-id="addBtn" onClick={() => this.handleOpeneAddListModal()} startIcon={<AddCircleOutlineRoundedIcon />}>Add</Button>
          </Box>

          {this.state.listData.length > 0 ? 
          <Box>
            
            <Typography className="catalougeInfoText"> Vehicles ({this.state.noOfCars})  •  Homes ({this.state.noOfHome})</Typography>
          
             <Box>
                <Grid container spacing={3}>
                  {this.state.listData.map((item: any) => {
                    return (
                      <Grid key={item.id} item md={4} sm={6} xs={12} spacing={3}>
                       <Card data-test-id="carasoule" onMouseEnter={this.sliderOn} onMouseLeave={this.sliderOff}  style={{ maxWidth: '390px', margin: 'auto', width: '100%', borderRadius: 16, overflow: 'hidden' }}>
                         <CardActionArea data-test-id="navigateToInfo" onClick={() => { this.navigateToListInfoPage(item.id,item.attributes.name) }}>
                            {item.attributes.status === "active" && <Box style={{ padding: '4px 18px', background: '#CBF8E8', color: '#08C27F',fontFamily: "Plus Jakarta Sans",fontWeight: 500, fontSize: "16px", borderRadius: "0 0 16px 0", position: 'absolute', zIndex: 111, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                               •  Active
                            </Box>}
                            <SliderWrapper>
                              <SimpleImageSlider
                                style={{ objectFit: 'cover' }}
                                width={"100%"}
                                height={190}
                                images={item.attributes?.images}
                                showBullets={true}
                                showNavs={false}
                                autoPlay={false}
                                autoPlayDelay={2.5}
                              />
                            </SliderWrapper>
                            <Typography className="catalougeDataText">
                              {this.handleCardTitle(item)}
                            </Typography>
                            <Typography className="catalougeDataText">
                              {this.handleCardHeader(item)}
                            </Typography>
                            <Typography className="catalougeDataText">
                              {this.handleCardTrips(item)}
                            </Typography>
                         </CardActionArea>
                       </Card>
                     </Grid>

                    )
                  })}
                  
                </Grid>
             </Box>
          </Box> : 
          <Box>
          <Typography className="catalougeInfoText" style={{ textAlign : "center"}}> No Data Available</Typography>
            </Box>}


            <Dialog open={this.state.addListModal} data-test-id="modalClosebyAwayClick" onClose={() => this.handleCloseAddListModal()}>
          <DialogTitle id="customized-dialog-title">
            <Box style={{ width: 480, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
               <Typography style={{
                 fontFamily: 'Plus Jakarta Sans',
                 fontSize: '18px',
                 fontWeight: 600,
               }}>Add to Listing</Typography> 
                <IconButton data-test-id="modalClosebyIcon" onClick={() => this.handleCloseAddListModal()}>
                   <HighlightOffRoundedIcon />
                </IconButton>
            </Box>
            <Divider />
          </DialogTitle>
          <DialogContent>
              <Box style={{ display: "flex", gap: "25px", margin: '10px 0'}}>
                 <img src={listHomeIcon} alt="icon" />
                 <Box>
                <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '18px',
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                data-test-id={'navigateToAddHome'}
                onClick={() => this.navigateToAddHomeListingPage() }
                >Add to Home</Typography>
                 <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: "#4C6081"
                }}>Add a Home to your listings</Typography>
                 </Box>
              </Box>

              <Box style={{ display: "flex", gap: "25px", margin: '30px 0'}}>
                 <img src={listCarIcon} alt="icon" />
                 <Box>
                <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '18px',
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                data-test-id={'navigateToAddCar'}
                onClick={() => this.navigateToAddCarListingPage() }
                >Add to Car</Typography>
                 <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: "#4C6081"
                }}>Add a Car to your listings</Typography>
                 </Box>
              </Box>
          </DialogContent>
            </Dialog>



        </ListingTabWrapper>
    )
  }

  EarningsWrapper = () => {

    const earningData = {
      series: [
        {
          name: "Total Earnings",
          data: this.state.earningGraphData, 
        },
      ],
      options: {
        chart: {
          type: "line",
          toolbar: { show: false },
          zoom: { enabled: false },
          background: "transparent",
        },
        stroke: {
          curve: "smooth",
          width: 3,
          colors: ["#ffffff"],
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.state.earningCatogoriesGraphData,
          labels: {
            style: {
              colors: "rgba(255, 255, 255, 0.7)",
              fontSize: "12px",
            },
          },
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: (value: number) => `$${value.toLocaleString()}`,
          },
        },
        markers: {
          size: 4,
          colors: ["#ffffff"],
          strokeWidth: 0,
        },
      } as ApexOptions,
    };
  
    return (
      <EarningTabWrapper>
        <Box className="graphMainBox">
        <p className="scheduleText">Schedule</p>

        <Box className="scheduleBox">
          <div id="chart">
          <div id="chart-timeline">
                <CardWrapper>
                  <div>
                    <Title>Total Earnings</Title>
                    <Amount>${this.state.totalEarning}</Amount>
                  </div>
                  <Duration>
                     <Select
                          disableUnderline
                          className="selectFilterText"
                          value={this.state.selectedMonthFilter}
                          onChange={this.handleMonthFilterChange}
                          data-test-id="monthFilter"
                          IconComponent={(props) => <KeyboardArrowDownIcon  {...props}/>}
                      >
                        {this.monthFilterArray.map(monthVal => 
                          <MenuItem key={monthVal.id} value={monthVal.value}>{monthVal.label}</MenuItem>
                        )}
                        
                     </Select>
                  </Duration>
                  <ApexCharts
                    options={earningData.options}
                    series={earningData.series}
                    type="line"
                    height="200"
                  />
                </CardWrapper>
          </div>
        </div>
        </Box>
        </Box>
        <Box style={{width:"100%"}}>
          <Box className="snapShotFlexBox">
            <p className="scheduleText">Snapshot</p>
            <Box className="snapShotFilterFlexBox">
              {this.snapShotMonthFilterArray.map(arrVal => 
                <Typography 
                   key={arrVal.id}
                   className={this.state.snapShotSelectedFilter === arrVal.value ? "filterLableActiveText" :"filterLableText"}
                   data-test-id="snapShotFilter"
                   onClick={() => this.handleSnapShotFilterChange(arrVal.value)}
                >
                  {arrVal.label}
                </Typography>
              )}
              
            </Box>
          </Box>
        <Box style={{ width: "100%", maxWidth: "1220px" }}>
          <Grid className="tmpDataGridContainer" container alignItems="center" spacing={3}>
            {this.state.snapShotGraphData.map(itemVal =>
              <Grid item key={itemVal.id} md={4} className="snapshotCard">
                <CircularProgressWithLabel 
                     value={itemVal.value} 
                     title={itemVal.progressTitle} 
                     showUpgrade={itemVal.progressUpgrade} 
                     upgradeCount={itemVal.progressUpgradeCount}
                  />
                <Typography className="titleText">{itemVal.label}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        </Box>
        <Box style={{width:"100%"}}>
        <RootBox>
        <StyledTabs
          value={this.state.value}
          data-test-id="handleChange"
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <StyledTab label="Payouts" {...this.a11yProps(0)} />
          <StyledTab label="Transactions" {...this.a11yProps(1)} />
          <StyledTab label="Expenses" {...this.a11yProps(2)} disabled/>
        </StyledTabs>
      </RootBox>
      <TabPanelPayouts value={this.state.value} index={0} className="mainPayouts">
            {this.renderPayoutTabSection()}
        </TabPanelPayouts>
        <TabPanelPayouts value={this.state.value} index={1} className="mainPayouts">
            {this.renderTransactionTabSection()}
        </TabPanelPayouts>
        <TabPanel value={this.state.value} index={2}>
            third
        </TabPanel>
      </Box>

        <CustomDialog data-test-id="payoutDialog" open={this.state.openPayoutModal} onClose={this.closePayoutModal} fullWidth
          PaperProps={{
            className: "setDialogueBox"
          }}
        >
          <DialogTitle className="dialogHeader">
            Payout Breakdown
            <IconButton aria-label="close" onClick={this.closePayoutModal}>
              <HighlightOffIcon style={{ color: "#475467" }} />
            </IconButton>
          </DialogTitle>
          <Divider style={{ marginTop : 15}} />
          <DialogContent className="setDialogeContent">
            <Box className="showPayout">
              <Box>
                <Typography className="headerText">{this.state.selectedPayOutData.title}</Typography>
                <Typography className="receivedText">Recieved on {this.state.selectedPayOutData.created_at}</Typography>
              </Box>
              <Typography className="showPayment">${this.state.selectedPayOutData.amount}</Typography>
            </Box>
            {this.state.selectedPayOutData.booked_slots.map((itemVal, index) => {
              return (
                <Box>
                  <Box className="payoutItems">
                    <Box className="itemDetails">
                      <Box>
                        <img src={itemVal.catalogueImageUrl} className="payoutImage" />
                      </Box>
                      <Box>
                        <Typography className="itemName">
                          {itemVal.catalogueName}
                        </Typography>
                        <Typography className="itemDuration">
                          {itemVal.catalogueDuration} ({itemVal.startDate}-{itemVal.endDate})
                        </Typography>
                      </Box>
                    </Box>
                    <Typography className="itemPrice">
                      ${itemVal.cataloguePrice}
                    </Typography>
                  </Box>
                  {this.state.selectedPayOutData.booked_slots.length - 1 !== index && <Divider style={{ margin: "10px 0" }} />}
                </Box>
              )
            })}
            
            <Divider />
          </DialogContent>
        </CustomDialog>

        <CustomDialog data-test-id="transactionDialog" open={this.state.openTransactionModal} onClose={this.closeTransactionModal} fullWidth
          PaperProps={{
            className: "setDialogueBox"
          }}
        >
          <DialogTitle className="dialogHeader">
            Transaction Breakdown
            <IconButton aria-label="close" onClick={this.closeTransactionModal}>
              <HighlightOffIcon style={{ color: "#475467" }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className="setDialogeContent">
            <Box className="showTransaction">
              <Box className="flexBoxTransaction">
                <Box className="breakdownHeadingBox">
                  <Box>
                    <img src={this.state.selectedTransactionData.booked_slots[0].catalogueImageUrl} alt="payoutImage" className="payoutImage" />
                  </Box>
                  <Box>
                    <Typography className="transactionBreakdownHeaderText">
                      {this.state.selectedTransactionData.booked_slots[0].catalogueName}
                    </Typography>
                    <Typography className="itemDuration">
                      {this.state.selectedTransactionData.booked_slots[0].catalogueDuration} ({this.state.selectedTransactionData.booked_slots[0].startDate}-{this.state.selectedTransactionData.booked_slots[0].endDate})
                    </Typography>
                  </Box>
                </Box>
                <Typography className="itemPrice">
                  ${this.state.selectedTransactionData.booked_slots[0].cataloguePrice}
                </Typography>
              </Box>

              <Box className="stepperBox">
                <CustomStepper steps={this.state.transactionPriceSteeper} />
              </Box>


              <Box className="total">
                <Typography className="totalReceived">
                  Total Received
                </Typography>
                <Typography className="totalPrice">
                   ${this.state.selectedTransactionData.booked_slots[0].cataloguePrice}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </CustomDialog>
      </EarningTabWrapper>
    )
  }

  ReviewsWrapper = () => {
    const {reviewData, replySection, showMore, replyValues, showEmojiPicker, seeAllBtn} = this.state
    const showReviewData= seeAllBtn? reviewData.reviews?.data : reviewData.reviews?.data.slice(0,5)
    return (
        <ReviewsTabWrapper>
          {reviewData.reviews.data.length>0? 
          <>
          <Box className="graphBox">
          <Typography className="avgText">{configJSON.avgRating}</Typography>
          <Box className="mainCard">
           <Box className="graphReviewBox">
                  <CircularProgressReview value={Number(reviewData.average_rating)}
                    rating1={reviewData.rating_1}
                    rating2={reviewData.rating_2}
                    rating3={reviewData.rating_3}
                    rating4={reviewData.rating_4}
                    rating5={reviewData.rating_5}
                  />
           <Box className="totalSection">
           <Typography className="totalReview">Total 512 reviews</Typography>
           </Box>
           </Box>
           {showReviewData.map((items: ReviewData, index:number) => {
            return (
              <Box className="reviewCard">
                  <Box className="headerBox">
                    <Box className="profileSection">
                      <img src={items.attributes.profile_img.url} className="profileImg" />
                      <Box>
                        <Typography className="reviewerName">{items.attributes.reviewer_name}</Typography>
                          <Box className="ratingDate">
                        <Rating name="read-only" value={items.attributes.rating} readOnly />
                        <Typography className="reviewerDate">{items.attributes.created_at}</Typography>
                        </Box> 
                      </Box>
                    </Box>
                    <img src={msgIcon} onClick={() => this.handleComment(index)} style={{cursor:"pointer"}} data-test-id="handleComment"/>
                  </Box>
                <Divider />
                <Typography className="showTime">{items.attributes.web_time_ago}</Typography>
                <Divider/>
                <Box className="commentSection">
                <Typography className="comment">{this.showComment(items.attributes.comment, index)}</Typography>
                <Box className="seeMoreBtn">
                {!showMore[index] ? <Button className="seeBtn" data-test-id="seeMoreBtn" onClick={() => this.seeMoreBtn(index)}>{configJSON.seeMoreText}</Button>
                  :
                <Button className="seeBtn" data-test-id="seeLessBtn" onClick={() => this.seeLessBtn(index)}>{configJSON.seeLessText}</Button>
                }<ChevronRightIcon className="forwarIcon"/>
                </Box>
                {replySection[index] && 
                <><TextField 
                className="replyField"
                value={this.state.replyValues[index] || ""}
                onChange={this.handleReply(index)}
                data-test-id="handleReply"
                placeholder={`@${items.attributes.reviewer_name} Thank You so much for your`}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="inputIcons" style={{ cursor: "pointer" }}>
                      <div tabIndex={0} onKeyDown={this.handleKeyPress} data-test-id="handleKeyPress">
                        <img src={emojiIcon} data-test-id="setSelectedReplyIndex" onClick={() => this.setSelectedReplyIndex(index)} />
                      </div>
                      <Button disabled={!replyValues[index]}>
                        <img src={replyIcon} onClick={() => this.sendReplyApiCall(items.id, index)} data-test-id="sendReplyApiCall" />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                />
               {showEmojiPicker && this.state.selectedReplyIndex === index && (
              <EmojiPicker onEmojiClick={this.onEmojiClick} data-test-id="onEmojiClick" />
            )}
                </>}
                </Box>
              </Box>
            )
          })}
          </Box>
          </Box>
          <Box className="allBtn">
          {reviewData.reviews?.data.length>5 && (!seeAllBtn ? 
          <Button className="allReviewBtn" data-test-id="handleSeeAllBtn" onClick={this.handleSeeAllBtn}>{configJSON.allReview}<KeyboardArrowDownIcon/></Button>:
          <Button className="allReviewBtn" data-test-id="handleSeeLessBtn" onClick={this.handleSeeLessBtn}>{configJSON.allLessReview}<KeyboardArrowUpIcon/></Button>)
            }
          </Box>
          </> :
          <Box className="noData">
          <Typography className="noDataText"> No Data Available</Typography>
            </Box>
  }
        </ReviewsTabWrapper>
    )
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader activeLink="Dashboard" navigation={this.props.navigation}/>

         {localStorage.getItem("userRole") !== "host" ? 
         <BecomeHostWrapper>

              <Typography className="becomeHostText">Become a host today!</Typography>

            <Box style={{ marginTop: 10, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>

            <IconButton disableFocusRipple disableRipple>
                <img src={list1} alt="logo" />
            </IconButton>
            <IconButton disableFocusRipple disableRipple>
                <img src={list2} alt="logo" />
            </IconButton> 
            <IconButton disableFocusRipple disableRipple>
                <img src={list3} alt="logo" />
            </IconButton>
            <IconButton disableFocusRipple disableRipple>
                <img src={list4} alt="logo" />
            </IconButton>
            </Box>

            <Button fullWidth data-test-id="hostBtn" onClick={() => this.becomeHost()} disableFocusRipple disableRipple>
                <img src={hostBtnLogo} alt="logo" />
            </Button>

         </BecomeHostWrapper>
          : 
         <>
          <TabWrapper>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        data-test-id='tabs'
                        indicatorColor="primary"
                        textColor="primary"
                        className="headerTabs"
                    >
                        <Tab className="tabTextLabel" label="Overview" />
                        <Tab className="tabTextLabel" label="Listings" />
                        <Tab className="tabTextLabel" label="Earnings" />
                        <Tab className="tabTextLabel" label="Reviews" />
                    </Tabs>   
          </TabWrapper>

           {this.handleTabView(this.state.tabValue)}
         </>
         }

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default HostDashboard;


const TabWrapper  = styled(Box) ({
    borderBottom : "1px solid #EAECF0", 
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "40px auto",
    "& .headerTabs .Mui-selected": {
      color: "#3173E1",
      borderBottom: "none"
    },
    "& .headerTabs .MuiTabs-indicator": {
      backgroundColor: "#3173E1",
    },
    "& .tabTextLabel" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
      textTransform: "none",
      color: "#23395D",
    }
})

const BecomeHostWrapper  = styled(Box) ({
  display: "flex", 
  flexWrap: "wrap", 
  maxWidth: "1220px", 
  margin: "40px auto",
  flexDirection: "column",
  "& .becomeHostText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '25.2px',
  }
})

const OverViewTabWrapper  = styled(Box) ({
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "auto",
    "& .scheduleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      textAlign: 'left'
    },
    "& .scheduleBox" : {
      width: '1220px',
      height: '279px',
      padding: '25px 66px 25px 0px',
      gap: '10px',
      borderRadius: '12px',
      opacity: '0px',
      "& .apexcharts-svg.apexcharts-zoomable.hovering-zoom": {
        display: "none !important"
      }
    }, 
    "& .gridBoxes" : {
      padding: '27px 24px',
      borderRadius: '8px',
      border: '1px solid #EAECF0', 
      boxShadow: "0px 4px 5px #EAECF0",
      margin: "30px 0"
    },
    "& .titleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '30.24px',
      textAlign: 'center',
      margin: "20px 0",
      minHeight:"60px",
    }, 
    "& .viewBtn" : {
      width: '124px',
      height: '34px',
      padding: '9px 24px',
      gap: '6px',
      borderRadius: '8px',
      border: '0.8px solid #7639BC',
      color: "#7639BC",
      textTransform: "none",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 600,
      margin: "20px 0"
    }
})

const ListingTabWrapper  = styled(Box) ({
    maxWidth: "1220px", 
    margin: "auto",
    "@media(max-width:1260px)": {
      padding: "20px"
    },
    "& .searchMainListingBox": {
      display: "flex",
      justifyContent: "space-between",
      "@media(max-width: 520px)": {
        gap: "15px"
      }
    },
    "& .searchBox" : {
      width: "380px",
      height: 55,
      padding: "10px 16px",
      color: "#475467",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 600,
      borderRadius: "12px",
      fontSize: "14px",
    },
    "& .searchBox .MuiOutlinedInput-input" : {
      padding : 10,
    },
    "& .addBtn" : {
      borderRadius: "8px",
      color: "White",
      background: "#0F73EE",
      textTransform: "none",
      width: "95px",
      height: "55px"
    },
    "& .catalougeInfoText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: "#23395D",
        margin : "15px 0px"

    }, 
    "& .catalougeDataText" :  {
       textAlign: "center",
       padding: '2px 0px 0px', 
       marginBottom: 0, 
       lineHeight: '24px', 
       fontSize: 16, 
       color: "#23395D", 
       fontWeight: 600,
       fontFamily: 'Plus Jakarta Sans', 
    }
})

const EarningTabWrapper  = styled(Box) ({
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "auto",
    msFlexDirection:"column",
    gap:"32px",
    "& .graphMainBox": {
      width: "100%"
    },
    "& .chartEarning": {
      "& .apexcharts-toolbar": {
        display: "none !important"
      }
    },
    "& .scheduleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
      color:"#23395D"
    },
    "& .tmpDataGridContainer": {
      display: 'flex', 
      gap: "24px",
      "@media(max-width:1240px)": {
        padding: "20px",
        justifyContent: "center",
      },
    },
    "& .snapshotCard":{
      background: "linear-gradient(92.64deg, #7A36BA 0%, #3173E1 114.09%)",
      maxWidth:"395px",
      borderRadius:"12px",
      padding:"24px 8px 31px 8px",
      minHeight:"279px",
      display:"flex",
      justifyContent:"center",
      flexDirection:"column",
      gap:"12px",
      alignItems:"center"
    },
    "& .titleText":{
    color:"#FFFFFF",
    fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'center',
    },
    "& .estimatedText" : {
      color: "#5B6A82",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 400,
    },
    "& .snapShotFlexBox" : {
       display: "flex",
       justifyContent : "space-between",
       alignItems : "center",
       marginBottom : 15
    },
    "& .snapShotFilterFlexBox" : {
       display : "flex",
       gap : 15,
       marginRight : 20
    },
    "& .filterLableText" : {
      color: "#B0B0B0",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      cursor : "pointer"
    },
    "& .filterLableActiveText" : {
      color: "#3173E1",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      cursor : "pointer"
    },
})


const ReviewsTabWrapper  = styled(Box) ({
    display: "flex", 
    flexDirection:"column",
    maxWidth: "1220px",
    margin: "auto",
    justifyContent:"center",
    alignItems:'center',
    "& .graphBox":{
      width:"100%",
      "@media (max-width:1220px)":{
        width:"80%",
      },
    },
    "& .avgText":{
      color:"#23395D",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"20px",
      fontWeight: 600,
      lineHeight: "30px",
      paddingBottom:"20px"
    },
    "& .mainCard":{
      display:"grid",
      gridTemplateColumns:"repeat(2,1fr)",
      rowGap:"20px",
      columnGap:"24px",
      width:"100%",
      "@media (max-width:1220px)":{
        gridTemplateColumns:"repeat(1,1fr)",
      },
      "@media (max-width:600px)":{
        gridTemplateColumns:"repeat(1,1fr)",
        display:'flex',
        flexDirection:"column"
      }
    },
    "& .totalReview":{
      color:"#3173E1",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"20px",
      fontWeight: 500,
      lineHeight: "20px",
      paddingBottom:"20px"
    },
    "& .totalSection":{
      backgroundColor:"#F9FAFB",
      padding:"24px 26px"
    },
    "& .graphReviewBox":{
       display:"flex",
       flexDirection:"column",
       gap:"20px"
    },
    "& .noData":{
      textAlign : "center", width:"100%"
    },
    "& .noDataText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      color:"#23395D"
    },
    "& .reviewCard": {
      border: "1px solid #E7E7E7",
      boxShadow: "0px 8px 32px 0px #0000000F",
      borderRadius:"12px",
      padding:"24px 24px 32px 24px",
    },
    "& .profileSection":{
     display:"flex",
     gap:"8px",
     alignItems:"center",
    },
    "& .profileImg": {
      width: "44px",
      height: "44px",
      borderRadius: "50%"
    },
    "& .reviewerName":{
      color:"#23395D",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 600,
      lineHeight: "20px",
      textAlign: "left"
    },
    "& .headerBox":{
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%" ,
    paddingBottom:"10px",
   
    },
    "& .reviewerDate":{
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 600,
      lineHeight: "20px",
    },
    "& .ratingDate":{
      display:"flex",
      gap:"12px",
      alignItems:"center",
      "@media(max-width:468px)":{
        flexDirection:"column"
      }
    },
    "& .showTime":{
      padding:"14px 0",
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    "& .commentSection":{
      paddingTop:"8px",
      display:"flex",
      flexDirection:"column",
      gap:"12px"
    },
    "& .comment":{
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 400,
      lineHeight: "26px",
      wordBreak:"break-all"
    },
    "& .seeBtn":{
      textTransform:"none",
      color:"#3173E1",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"14px",
      fontWeight: 600,
      lineHeight: "20px",
      padding:"0px !important",
      cursor:"pointer"
    },
    "& .seeMoreBtn":{
      display:"flex",
      gap:"6px",
      alignItems:"center"
    },
    "& .forwarIcon":{
      color:"#3173E1",

    },
    "& .replyField":{
      backgroundColor:"#EFF2F8",
      borderRadius:"8px",
      padding:"18px 24px",
    },
    "& .inputIcons":{
      display:"flex",
      alignItems:"center",
    },
    "& .allReviewBtn":{
      textTransform:"none",
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"14px",
      fontWeight: 600,
      lineHeight: "20px",
      width:"100%", 
      textAlign:"center",
      border:"1px solid #D0D5DD",
      padding:"12px 0px",
      borderRadius:"30px",
      display:"flex",
      alignItems:"center",
      gap:'10px'
    },
    "& .allBtn":{
      paddingTop:"112px",
      width:"100%",
      cursor:"pointer",
      "@media (max-width:1220px)":{
        width:"80%",
      },
    }

})

const SliderWrapper = styled(Box)({
  '& button': {
    height: '5px !important',
    width: '5px !important',
    background: 'lightgray !important',
    border: 'none !important',
  }
})
const RootBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  flexDirection:"column",
});

const StyledTabs = styled(Tabs)({
  borderRadius: "50px",
  backgroundColor: "#F2F4F7",
  border: "1px solid #D0D5DD",
  width:"354px"
});

const StyledTab = styled(Tab)({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: "14px",
  minWidth: 120,
  fontFamily:"Plus Jakarta Sans",
  lineHeight:"20px",
  borderRight:"1px solid #D0D5DD",
  cursor:"pointer",
  "&.Mui-selected": {
    backgroundColor: "#3173E1",
    color: "white",
  },
});

const TabPanelPayouts = styled(TabPanel)({
  marginTop: "18px",
  "& .payoutBox": {
    borderTop:"1px solid #EAECF0", 
    borderBottom:"1px solid #EAECF0", 
    width:'100%', 
    display: "flex", 
    alignItems: "center", 
    padding: "16px", 
    gap: "10px",
    justifyContent: "space-between",
    cursor:"pointer"
  },
  "& .payoutImg": {
    height: "60px",
    width: "60px"
  },
  "& .payoutPrice" :{
    display:"flex", 
    flexDirection: "column"
  },
  "& .revenue": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 600,
    fontSize: "26px",
    color: "#3173E1"
  },
  "& .estimated": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#5B6A82"
  },
  "& .arrowAndTick": {
    display: "flex",
    gap: "10px"
  },
  "& .tickIcon": {
    height: "24px",
    width: "24px"
  },
  "& .arrowIcon": {
    height: "24px",
    width: "24px"
  },
  "& .details": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  }
})
const CustomDialog = styled(Dialog)({
"& .dialogHeader":{
  "& .MuiTypography-root":{
  display:"flex",
  justifyContent:"space-between",
  width:"100%",
  alignItems:"center",
  fontFamily: "Plus Jakarta Sans",
fontSize: "18px",
fontWeight: 600,
lineHeight: "28px"
}
},
 "& .setDialogueBox .MuiDialogTitle-root" : {
    padding : "0 !important",
 },
"& .setDialogueBox":{
  maxWidth:"480px",
  borderRadius:"16px",
  padding:"20px"
},
"& .setDialogeContent":{
  border:"1px solid #E7E7E7",
  borderRadius:"12px",
    padding:"0 !important",
    marginTop:"24px",
    overflowX: "hidden"
},
"& .showPayout":{
  backgroundColor:"#F4F7FC",
  width:"100%", 
  display:"flex",
  justifyContent:"space-between",
  alignItems:'center',
  maxWidth: "95%",
  padding: "20px 16px 20px 16px"
},
"& .showPayment":{
  fontFamily: "Plus Jakarta Sans",
fontSize: "22px",
fontWeight: 600,
lineHeight: "27.72px",
textAlign: "right",
color:"#3173E1"
},
"& .headerText":{
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22.68px",
  color:"#23395D",
},
"& .receivedText" : {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  color:"#B0B0B0",
},
"& .payoutItems": {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px 14px 14px 14px",
},
"& .itemDetails": {
  display: "flex",
  gap: "10px"
},
"& .itemName": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22.68px",
  color:"#23395D",
},
"& .itemYear": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "17.64px",
  color:"#23395D",
},
"& .payoutImage": {
  height: "62px",
  width: "77px",
  borderRadius : "14px"
},
"& .itemDuration": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17.64px",
  color:"#596C8A",
},
"& .itemPrice": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20.16px",
  color:"#3173E1",
},
"& .breakdownHeadingBox": {
  display: "flex",
  gap: "16px",
  marginBottom: "16px",
  alignItems: "center",
},
"& .transactionBreakdownHeaderText": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .breakdownDesc": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#23395D",
},
"& .showTransaction":{
  padding: "20px 15px"
},
"& .stepperTransaction": {
  backgroundColor:"#F5F5F6",
  borderRadius: "10px",
  marginBottom: "16px",
  "& .MuiStepConnector-lineVertical": {
    minHeight: "50px !important"
  },
  "& .MuiStepConnector-line": {
    borderColor: "#3173E1 !important"
  },
  "& .MuiStepContent-root": {
    marginTop: "0px !important"
  },
  "& .MuiStepConnector-vertical": {
    padding: "0px !important"
  }
},
"& .stepLabelBox": {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
},
"& .stepLabelHeading": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .rentalDuration": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#B0B0B0",
},
"& .rentalTime": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#3173E1",
},
"& .rentalPrice": {
  fontFamily: "Montserrat !important",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.5px",
  color:"#3173E1",
},
"& .rentalPriceRed": {
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.5px",
  color:"#DC3D28",
},
"& .total": {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop : 20
},
"& .totalReceived": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .totalPrice": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px",
  fontWeight: 600,
  lineHeight: "27.72px",
  color:"#3173E1",
},
"& .stepperBox" : {
  background : "#F5F5F6",
  padding : "15px"
},
"& .flexBoxTransaction" : {
  display : "flex",
  justifyContent : "space-between",
  alignItems : "center"
}
})

const CardWrapper = styled(Box)({
  background: "linear-gradient(135deg, #6b2fbb, #1e7cc8)",
  borderRadius: "16px",
  padding: "20px",
  color: "white",
  width: "96%",
  height: "250px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
});

// Title styling
const Title = styled(Box)({
  fontSize: "12px",
});

// Amount styling
const Amount = styled(Box)({
  fontSize: "24px",
  fontWeight: "bold",
});

const Duration = styled(Box)({
  position: "absolute",
  top: "20px",
  right: "20px",
  "& .selectFilterText" : {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 600,
    color:"#E7E7E7",
    "& .MuiSelect-icon" : {
      color:"#E7E7E7",
    }
  }
});

// Customizable Area End